export const data = {
  title: 'Smart Agricultural Robotics',
  subTitle: 'Use Cases',
  items: [
    {
      number: 'Item 1',
      title: 'Accelerated Maintenance Procedures',
      description: `
      <p>Boost your maintenance operations and reduce production downtime with rapid Gen AI-based analysis and troubleshooting.</p>
      <p>The Gen AI Industrial Assistant draws on your documentation to boost workflow efficiency. 
      It answers procedure-related queries and provides equipment feature insight using static data and metadata.</p>
      <p>SoftServe Gen AI Industrial Assistant was designed for process engineers, maintenance crews, technicians, 
      and automation engineers. It offers a superior maintenance strategy with a top-tier support system.</p>
      `,
    },
    {
      number: 'Item 2',
      title: 'AI-Driven Production Performance Analysis',
      description: `
      <p>Elevate decision-making with 3D data visualization. 
      Identify the top contributors to OEE KPIs. Analyze past performance and use it to forecast future operations. </p>
      <p>Do you want to see OEE contribution percentages for two days, one week, or even three months? 
      The Gen AI Industrial Assistant can analyze historical data spanning different time periods. </p>
      <p>Give your workers insight into future scenarios with a dynamic recommendation system. 
      Help them understand the potential outcomes of taking no action. Give them a powerful tool to explore 
      the impact of intervention. Deeper dives into production performance are now possible with Gen AI Industrial Assistant.</p>
      `,
    },
  ],
};
