import React from 'react';
import { motion } from 'framer-motion';
import { sanitizeHtml } from 'utils';

//Assets
import { data } from './data';
import { useCheckMobile } from '../../../../hooks';

import './HowToUse.scss';

export const HowToUse = () => {
  const isMobile = useCheckMobile({ width: 767 });

  return (
    <section id="how-to-use" className="how-to-use">
      <div className="how-to-use__wrapper">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? {
                  visible: { y: 0, opacity: 1 },
                }
              : {
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: -200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 0.5 }}
        >
          <span className="sub-title">How to use</span>
        </motion.div>
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? {
                  visible: { y: 0, opacity: 1 },
                }
              : {
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: -200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 0.8, delay: 0.3 }}
        >
          <h2>
            <span>{data.title}</span>
          </h2>
        </motion.div>
        <div className="how-to-use__items-block">
          <div className="how-to-use__item">
            <div className="how-to-use__item-text">
              {data.items.map(({ number, image, title, content }, i) => (
                <motion.div
                  key={number}
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true }}
                  variants={
                    isMobile
                      ? {
                          visible: { y: 0, opacity: 1 },
                        }
                      : {
                          visible: { y: 0, opacity: 1 },
                          hidden: { y: 200, opacity: 0 },
                        }
                  }
                  transition={{ type: 'anticipate', duration: 0.8, delay: (i - 0.2) / 2 }}
                >
                  <div className="how-to-use__item-icon">
                    <img src={image} alt="" />
                  </div>
                  <h3 dangerouslySetInnerHTML={{ __html: sanitizeHtml(title) }} />
                  <div className="how-to-use__item-text" dangerouslySetInnerHTML={{ __html: sanitizeHtml(content) }} />
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowToUse;
