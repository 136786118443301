export const data = {
  title: `    
    <span>Access real-time production equipment data and documents.</span><br/>
    Offer a new level of intuitive worker support with SoftServe Gen AI Industrial Assistant.
  `,
  description: `
    <p>
    Guide your workers through complex maintenance processes and boost OEE. SoftServe Gen AI Industrial 
    Assistant supports workers with real-time advice and guidance. It offers detailed instructions and autogenerated insights. 
    Workers gain rapid navigation through equipment manuals, procedures, and records.     
    </p>
    <p>
      The Gen AI Industrial Assistant eliminates training errors caused by gaps. 
      It facilitates confident technical knowledge transfer during onboarding, backup, or change management. 
    </p>
  `,
};

//Out dated text
//<span>Manufacturingis changing fast.</span><br/> SoftServe GenAI Industrial Assistant keeps your workers up tospeed.
