import how_it_works_image_1 from '../../../../../asset/resource/how_it_works_image_1.png';
import how_it_works_image_2 from '../../../../../asset/resource/how_it_works_image_2.png';
import how_it_works_image_3 from '../../../../../asset/resource/how_it_works_image_3.png';
import how_it_works_image_4 from '../../../../../asset/resource/how_it_works_image_4.png';

export const data = {
  title: 'SoftServe Gen AI Industrial Assistant technology',
  items: [
    {
      image: how_it_works_image_1,
      number: 'Item 1',
      title: 'Generative AI',
      description: `<p>A large language model of your documentation based on the NVIDIA NeMo™ 
        framework provides contextualized information. The model can access historic documentation, 
        as well as real-time production data. </p>
        <p>Real-time assessment of IIoT equipment data allows workers to speed up troubleshooting and planning.</p>`,
    },
    {
      image: how_it_works_image_2,
      number: 'Item 4',
      title: 'Natural Language Interface',
      description: `
        <p>A vocal interface simplifies interaction. The Gen AI Industrial Assistant accelerates worker knowledge acquisition. 
        It creates more time for task performance. </p>
        <p>NVIDIA® Riva ASR converts spoken language into text. NVIDIA TTS enables the Gen AI Industrial 
        Assistant to replay in any spoken language.</p>`,
    },
    {
      image: how_it_works_image_3,
      number: 'Item 2',
      title: 'Dynamic 3D Models',
      description: `<p>The Gen AI Industrial Assistant is built on the NVIDIA Omniverse™ platform. 
      It provides workers with a dynamic real-time production process visualization. </p>
        <p>Paired with live data sourced from integrated IIoT, 
        Gen AI Industrial Assistant generates a production environment digital twin. 
        Workers localize problems, understand system impact, and arrive at the fastest solution.   </p>`,
    },
    {
      image: how_it_works_image_4,
      number: 'Item 3',
      title: 'Customized Dashboard',
      description: `<p>The Gen AI Industrial Assistant features an enhanced UI with a custom dashboard. 
      It provides quick and easy access to your production knowledge bases. 
      The dashboard enhances manuals and service guidelines with digitization and summarization.</p>
      <p>Workers access task-based data analytics. It speeds up problem-solving and optimizes solutions. </p>`,
    },
  ],
};
