import message_1 from '../../../../../asset/resource/message_1.png';
import message_2 from '../../../../../asset/resource/message_2.png';
import message_3 from '../../../../../asset/resource/message_3.png';
import message_4 from '../../../../../asset/resource/message_4.png';
import message_5 from '../../../../../asset/resource/message_5.png';

export const data = {
  title: `SOFTSERVE GEN AI INDUSTRIAL<br> Assistant`,
  subTitle: 'Support Your Workers With ',
};

export const messageItems = [
  {
    name: 'image-1',
    image: message_1,
  },
  {
    name: 'image-2',
    image: message_2,
  },
  {
    name: 'image-3',
    image: message_3,
  },
  {
    name: 'image-4',
    image: message_4,
  },
  {
    name: 'image-5',
    image: message_5,
  },
];
