import React from 'react';

import softserve_logo from '../../../asset/resource/softserve_logo.png';

import './Footer.scss';

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__wrapper">
        <a
          className="footer__url"
          href="https://softserveinc.com"
          target="_blank"
          rel="noopener noreferrer"
          role="link"
        >
          <img src={softserve_logo} className="footer__logo" alt="Softserve Logo" />
        </a>
        <span className="footer__copyright">© Copyright 2024 SoftServe Inc.</span>
      </div>
    </footer>
  );
};

export default Footer;
