import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { motion } from 'framer-motion';
import video_copilot from '../../../../../asset/resource/video_copilot.mp4';
import video_copilot_webm from '../../../../../asset/resource/video_copilot.webm';
import { data, messageItems } from './data';
import { sanitizeHtml } from 'utils';
import { useCheckMobile } from '../../../../hooks';

import './Title.scss';

export const Title = () => {
  const isMobile = useCheckMobile({ width: 767 });
  const isPhone = useCheckMobile({ width: 450 });
  const [isStart, setIsStart] = useState(false);
  const [isRepeat, setRepeat] = useState(true);
  const [isSafariBrowser, setIsSafariBrowser] = useState(false);

  useEffect(() => {
    if (!isPhone) {
      const video = document.getElementById('box-video');
      setTimeout(function () {
        video.play();
      }, 2000);
    }

    setInterval(() => {
      setRepeat(true);
    }, 28000);

    let isSafari = window.navigator.vendor.toLowerCase().indexOf('apple') > -1;
    if (isSafari) setIsSafariBrowser(true);
  }, [isSafariBrowser, isPhone]);

  const animate = {
    y: [200, 10, 10, 10, 10, 0],
    opacity: [0, 1, 1, 1, 0],
  };

  const onComplete = (i, l) => {
    if (i === l) {
      setTimeout(() => {
        setRepeat(false);
      }, 1500);
    }
  };

  return (
    <section className={cn('main-title', isPhone && 'main-title__mobile-bg')}>
      <div className="main-title__wrapper">
        <div className="main-title__heading">
          <div className={cn('main-title__description', isStart && 'visible')}>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              onAnimationComplete={() => setIsStart(true)}
              variants={
                isMobile
                  ? {
                      visible: { y: 0, opacity: 1 },
                    }
                  : {
                      visible: { y: 0, opacity: 1 },
                      hidden: { y: -200, opacity: 0 },
                    }
              }
              transition={{ type: 'anticipate', duration: 1 }}
            >
              <span className="main-title__subtitle-colored-text">{data.subTitle}</span>
            </motion.div>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={
                isMobile
                  ? {
                      visible: { y: 0, opacity: 1 },
                    }
                  : {
                      visible: { y: 0, opacity: 1 },
                      hidden: { y: -200, opacity: 0 },
                    }
              }
              transition={{ type: 'anticipate', duration: 0.8, delay: 0.3 }}
            >
              <h1 dangerouslySetInnerHTML={{ __html: sanitizeHtml(data.title) }} />
            </motion.div>
          </div>
        </div>
      </div>
      {isPhone && (
        <div className="main-title__bg_static">
          {isRepeat && (
            <div className="main-title__animation-block">
              {messageItems.map(({ name, image }, i) => (
                <motion.div
                  animate={animate}
                  key={name}
                  transition={{ type: 'linear', duration: 5, delay: i * 5 }}
                  onAnimationComplete={() => onComplete(i, messageItems.length - 1)}
                >
                  <img src={image} alt="" className="main-title__animated-image" />
                </motion.div>
              ))}
            </div>
          )}
        </div>
      )}
      {isPhone ? null : (
        <video id="box-video" className="main-title__bg" width="100%" loop muted playsInline>
          {isSafariBrowser ? (
            <source src={video_copilot} type="video/mp4" />
          ) : (
            <source src={video_copilot_webm} type="video/webm" />
          )}
          Your browser is not supported!
        </video>
      )}
    </section>
  );
};

export default Title;
