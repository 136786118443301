import React from 'react';
import { motion } from 'framer-motion';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { sanitizeHtml } from 'utils';

//Assets
import { data } from './data';

import { useCheckMobile } from '../../../../hooks';

import './UseCases.scss';

const UseCases = () => {
  const isMobile = useCheckMobile({ width: 767 });

  return (
    <section id="use-cases" className="use-cases">
      <div className="use-cases__wrapper">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={
            isMobile
              ? {
                  visible: { y: 0, opacity: 1 },
                }
              : {
                  visible: { x: 0, opacity: 1 },
                  hidden: { x: -200, opacity: 0 },
                }
          }
          transition={{ type: 'anticipate', duration: 0.3 }}
        >
          <h2>
            <span className="sub-title">{data.subTitle}</span>
          </h2>
        </motion.div>
        <div className="use-cases__slider-wrapper">
          {data.items.map(({ number, title, description }, i) => (
            <motion.div
              key={number}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={
                isMobile
                  ? {
                      visible: { y: 0, opacity: 1 },
                    }
                  : {
                      visible: {
                        perspective: 800,
                        rotateY: -5,
                        translateY: -50,
                        rotateX: 0,
                        scale: 1,
                        filter: blur(0),
                        opacity: 1,
                      },
                      hidden: {
                        perspective: 800,
                        rotateY: 25,
                        rotateX: 20,
                        scale: 1,
                        filter: blur(0),
                        opacity: 0,
                      },
                    }
              }
              transition={{ type: 'anticipate', duration: 0.8, delay: (i - 0.2) / 2 }}
            >
              <div className="use-cases__slide" key={number}>
                <article>
                  <h3>{title}</h3>
                  <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(description) }} />
                </article>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default UseCases;
