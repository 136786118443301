import React from 'react';

import Header from '../../components/Header';
import Footer from '../../components/Footer/Footer';
import FormSection from './sections/FormSection/FormSection';
import Title from './sections/Title/Title';
import Banner from './sections/Banner/Banner';
import HowItWorks from './sections/HowItWorks/HowItWorks';
import HowToUse from './sections/HowToUse/HowToUse';
import UseCases from './sections/UseCases/UseCases';

import './Main.scss';

function Main() {
  return (
    <div className="main" id="home">
      <Header />
      <Title />
      <Banner />
      <HowItWorks />
      <HowToUse />
      <div className="main__common-bg">
        <UseCases />
        <FormSection />
      </div>
      <Footer />
    </div>
  );
}

export default Main;
